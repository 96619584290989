import React from "react";
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Listing from '../components/Listing'

export default ({data}) => {
  const props = {
      title: "Blog Posts",
      subtext: "Random Digital Scribblings",
      data: data,
      posts: data.allFile.edges,
      totalCount: data.allFile.totalCount,
      type: "childMarkdownRemark"
  };
    return (
        <Layout fullMenu>
          <Listing props={ props } />
        </Layout>
    )
}
export const query = graphql`
query BlogList {
  allFile(filter: {sourceInstanceName: {eq: "blog"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}) {
    edges {
      node {
        childMarkdownRemark {
          id
          excerpt
          frontmatter {
            title
            date(fromNow: true)
            category
          }
          fields {
            slug
          }
        }
      }
    }
    totalCount
  }
}
`